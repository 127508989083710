import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G02Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g02Guid
      this.g02Guid = props.g02Guid
      this.g26Guid = props.g26Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.engineeringListGuid = props.engineeringListGuid
      this.currentMonthNum = props.currentMonthNum
      this.currentMonthPercent = props.currentMonthPercent
      this.totalMonthNum = props.totalMonthNum
      this.totalMonthPercent = props.totalMonthPercent
      this.totalNum = props.totalNum
      this.totalPercent = props.totalPercent
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
      this.year = props.year
      this.month = props.month
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g02Guid) this.g02Guid = ''
    if (!this.g26Guid) this.g26Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.engineeringListGuid) this.engineeringListGuid = ''
    if (!this.currentMonthNum) this.currentMonthNum = ''
    if (!this.currentMonthPercent) this.currentMonthPercent = ''
    if (!this.totalMonthNum) this.totalMonthNum = ''
    if (!this.totalMonthPercent) this.totalMonthPercent = ''
    if (!this.totalNum) this.totalNum = ''
    if (!this.totalPercent) this.totalPercent = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.year) this.year = 0
    if (!this.month) this.month = 0
  }

  generatePrimaryKey () {
    this.g02Guid = utility.getUuid()
    this.resourceId = this.g02Guid
  }
}
